import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import { Button, Grid, FormControl, TextField, InputLabel } from '@material-ui/core';
import {
    required,
    isNotBlank,
    isNotWhitespace,
    minLengthNoWhitespace,
    composeValidators,
    maxLength,
} from 'utils/validators';
import MultiCheckSelect from '../../../../components/MultiCheckSelect';
import TextDisabled from '../../../../components/TextDisabled/Loadable';
import { comparator } from '../../../../utils/sortComparator';

const useStyles = makeStyles((theme) => ({
    submitButton: {
        height: 42,
        borderRadius: 4,
        padding: 0,
        marginBottom: '24px',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.humanBlue,
        border: 'none !important',
        color: theme.palette.standardWhite,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
        '&:hover:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
    inputLabel: {
        height: 14,
        paddingBottom: '12px',
        color: theme.palette.techBlack,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 14,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    fieldContainer: {
        paddingBottom: '36px',
    },
}));

function isNotDuplicateAccount(initialValues, accounts) {
    return (accountName) => {
        if (
            accountName !== initialValues.providerAccountName &&
            accounts.some((account) => account.providerAccountName === accountName)
        ) {
            return 'Account name already exists.';
        }
        return undefined;
    };
}

function AddEditAccountForm({
    onSubmit,
    submitDisabled,
    initialValues,
    organizations = [],
    brands = [],
    accounts = [],
    isEdit,
}) {
    const classes = useStyles();

    const orgOptions = organizations
        .slice()
        .sort((a, b) => comparator(a, b))
        .map((org) => ({ name: org.name, value: org.orgId }));

    const brandOptions = brands
        .slice()
        .sort((a, b) => comparator(a, b))
        .map((brand) => ({ name: brand.name, value: brand.id }));

    const accountTypes = [
        { name: 'Vendor', value: 'vendor' },
        { name: 'Vendor Rep', value: 'vendor rep' },
        { name: 'Agency', value: 'agency' },
    ];

    return (
        <>
            <Form
                keepDirtyOnReinitialize
                onSubmit={onSubmit}
                initialValues={initialValues}
                render={({ handleSubmit, submitting, pristine, hasValidationErrors }) => (
                    <form onSubmit={handleSubmit}>
                        {!isEdit && (
                            <>
                                <Grid item xs={12}>
                                    <Field
                                        name="organization"
                                        component="select"
                                        type="text"
                                        validate={composeValidators(required, isNotBlank)}
                                    >
                                        {({ input, meta }) => (
                                            <Grid item xs={12} className={classes.fieldContainer}>
                                                <InputLabel className={classes.inputLabel}>Organization</InputLabel>
                                                <div>
                                                    <FormControl margin="dense" variant="outlined" fullWidth>
                                                        <MultiCheckSelect
                                                            {...input}
                                                            multiple={false}
                                                            checkbox={false}
                                                            placeholder="Search Organizations"
                                                            values={input.value || []}
                                                            options={orgOptions}
                                                            onBlur={(event) => input.onBlur(event)}
                                                            onChange={(newValue) => {
                                                                input.onChange(newValue);
                                                            }}
                                                            noOptionsText="No Options available"
                                                        />
                                                    </FormControl>
                                                    {meta.error && meta.submitFailed && (
                                                        <span className={classes.error}>
                                                            Please select an organization type.
                                                        </span>
                                                    )}
                                                </div>
                                            </Grid>
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="myadsBrand"
                                        component="select"
                                        type="text"
                                        validate={composeValidators(required, isNotBlank)}
                                    >
                                        {({ input, meta }) => (
                                            <Grid item xs={12} className={classes.fieldContainer}>
                                                <InputLabel className={classes.inputLabel}>Brand</InputLabel>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <MultiCheckSelect
                                                        {...input}
                                                        multiple={false}
                                                        checkbox={false}
                                                        placeholder="Search Brands"
                                                        values={input.value || []}
                                                        options={brandOptions}
                                                        onBlur={(event) => input.onBlur(event)}
                                                        onChange={(newValue) => {
                                                            input.onChange(newValue);
                                                        }}
                                                        noOptionsText="No Options available"
                                                    />
                                                </FormControl>
                                                {meta.error && meta.submitFailed && (
                                                    <span className={classes.error}>Please select a brand.</span>
                                                )}
                                            </Grid>
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={12} className={classes.fieldContainer}>
                                    <InputLabel className={classes.inputLabel}>Provider</InputLabel>
                                    <TextDisabled text={initialValues.provider} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="providerBrandId"
                                        component="input"
                                        type="text"
                                        validate={composeValidators(required, isNotBlank, isNotWhitespace)}
                                    >
                                        {({ input, meta }) => (
                                            <Grid item xs={12} className={classes.fieldContainer}>
                                                <InputLabel className={classes.inputLabel}>
                                                    Sales Cloud Case Safe Brand ID
                                                </InputLabel>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <TextField
                                                        {...input}
                                                        id="providerBrandId"
                                                        placeholder="Enter Sales Cloud Case Safe Brand ID"
                                                        margin="dense"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </FormControl>
                                                {meta.error && meta.submitFailed && (
                                                    <span className={classes.error}>
                                                        Please enter a valid Brand ID.
                                                    </span>
                                                )}
                                            </Grid>
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="providerAccountType"
                                        component="select"
                                        type="text"
                                        validate={composeValidators(required, isNotBlank)}
                                    >
                                        {({ input, meta }) => (
                                            <Grid item xs={12} className={classes.fieldContainer}>
                                                <InputLabel className={classes.inputLabel}>
                                                    Select Account Type
                                                </InputLabel>
                                                <div>
                                                    <FormControl margin="dense" variant="outlined" fullWidth>
                                                        <MultiCheckSelect
                                                            {...input}
                                                            multiple={false}
                                                            checkbox={false}
                                                            placeholder="Select Account Type"
                                                            values={input.value || []}
                                                            options={accountTypes}
                                                            onBlur={(event) => input.onBlur(event)}
                                                            onChange={(newValue) => {
                                                                input.onChange(newValue);
                                                            }}
                                                            noOptionsText="No Options available"
                                                        />
                                                    </FormControl>
                                                    {meta.error && meta.submitFailed && (
                                                        <span className={classes.error}>
                                                            Please select an account type.
                                                        </span>
                                                    )}
                                                </div>
                                            </Grid>
                                        )}
                                    </Field>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <Field
                                name="providerAccountName"
                                component="input"
                                type="text"
                                validate={composeValidators(
                                    required,
                                    isNotBlank,
                                    isNotWhitespace,
                                    minLengthNoWhitespace(2),
                                    maxLength(50),
                                    isNotDuplicateAccount(initialValues, accounts),
                                )}
                            >
                                {({ input, meta }) => (
                                    <Grid item xs={12} className={classes.fieldContainer}>
                                        <InputLabel htmlFor="providerAccountName" className={classes.inputLabel}>
                                            {isEdit ? 'Account Name' : 'Salesforce Sales Cloud Account Name'}
                                        </InputLabel>
                                        <FormControl margin="dense" variant="outlined" fullWidth>
                                            <TextField
                                                {...input}
                                                id="providerAccountName"
                                                placeholder="Enter Sales Cloud Account Name"
                                                margin="dense"
                                                variant="outlined"
                                                fullWidth
                                                value={input.value || initialValues.providerAccountName}
                                            />
                                        </FormControl>
                                        {meta.error && meta.submitFailed && (
                                            <span className={classes.error}>Please enter a valid Account Name.</span>
                                        )}
                                    </Grid>
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="providerAccountId"
                                component="input"
                                type="text"
                                validate={composeValidators(required, isNotBlank, isNotWhitespace)}
                            >
                                {({ input, meta }) => (
                                    <Grid item xs={12} className={classes.fieldContainer}>
                                        <InputLabel htmlFor="providerAccountId" className={classes.inputLabel}>
                                            {isEdit ? 'Account ID' : 'Sales Cloud Case Safe Account ID'}
                                        </InputLabel>
                                        <div>
                                            <FormControl margin="dense" variant="outlined" fullWidth>
                                                <TextField
                                                    {...input}
                                                    id="providerAccountId"
                                                    placeholder="Enter Sales Cloud Case Safe Account ID"
                                                    margin="dense"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={input.value || initialValues.providerAccountId}
                                                />
                                            </FormControl>
                                            {meta.error && meta.submitFailed && (
                                                <span className={classes.error}>Please enter a valid Account ID.</span>
                                            )}
                                        </div>
                                    </Grid>
                                )}
                            </Field>
                        </Grid>
                        <Button
                            data-testid="submit-add-edit-account-form-button"
                            className={classes.submitButton}
                            type="submit"
                            disabled={submitting || hasValidationErrors || pristine || submitDisabled}
                        >
                            {isEdit ? 'Update' : 'Add Account'}
                        </Button>
                    </form>
                )}
            />
        </>
    );
}

AddEditAccountForm.propTypes = {
    onSubmit: PropTypes.func,
    submitDisabled: PropTypes.bool,
    initialValues: PropTypes.object,
    organizations: PropTypes.array,
    brands: PropTypes.array,
    accounts: PropTypes.array,
    isEdit: PropTypes.bool,
};

export default AddEditAccountForm;
