import React from 'react';
import { Field, Form } from 'react-final-form';
import {
    composeValidators,
    isNotBlank,
    isNotWhitespace,
    maxLength,
    minLengthNoWhitespace,
    required,
} from 'utils/validators';
import { Button, Grid, makeStyles } from '@material-ui/core';
import Input from 'components/Input';
import { VALIDATE_ON_MODIFIED } from 'utils/constants';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: 'white',
    },
    formButton: {
        height: 42,
        borderRadius: 4,
        padding: 0,
        marginTop: '24px',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.humanBlue,
        border: 'none !important',
        color: theme.palette.standardWhite,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
}));

function isNotDuplicateBrand(initialValues, brands) {
    return (brandName) => {
        if (brandName !== initialValues.name && brands.some((b) => b.name === brandName)) {
            return 'Brand name already exists.';
        }

        return undefined;
    };
}

function AddEditBrandForm({ initialValues, onSubmit, submitDisabled, brands = [], isEdit }) {
    const classes = useStyles();
    return (
        <Form
            initialValues={initialValues}
            autoComplete="off"
            keepDirtyOnReinitialize
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting, hasValidationErrors, pristine }) => (
                <form onSubmit={handleSubmit} className={classes.content}>
                    <Grid item xs={12}>
                        <Field
                            name="name"
                            component="input"
                            type="text"
                            validate={composeValidators(
                                required,
                                isNotBlank,
                                isNotWhitespace,
                                minLengthNoWhitespace(2),
                                maxLength(50),
                                isNotDuplicateBrand(initialValues, brands),
                            )}
                        >
                            {(props) => (
                                <Input
                                    inputLabel="Brand Name"
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    inputProps={{
                                        'data-testid': 'add-edit-brand-name-input',
                                        'aria-label': 'brandName',
                                        maxLength: 255,
                                        id: 'add-edit-brand-name',
                                    }}
                                    validateOn={VALIDATE_ON_MODIFIED}
                                    {...props}
                                />
                            )}
                        </Field>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            data-testid="submit-brand-button"
                            className={classes.formButton}
                            type="submit"
                            disabled={submitting || hasValidationErrors || pristine || submitDisabled}
                        >
                            {isEdit ? 'Update' : 'Add Brand'}
                        </Button>
                    </Grid>
                </form>
            )}
        />
    );
}

AddEditBrandForm.propTypes = {
    initialValues: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitDisabled: PropTypes.bool,
    brands: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
        }),
    ),
    isEdit: PropTypes.bool,
};

export default AddEditBrandForm;
