import React from 'react';
import { RequestStatus } from '../../shared/constants';
import { FlyoutContent } from '../../components/FlyoutModal';
import TextDisabled from '../../components/TextDisabled';

export const DialogAction = {
    SHARE: 'share',
    UNSHARE: 'unshare',
};

export const formatSubmitValues = (organization, values) => {
    const { organizations, brands } = values;

    return {
        sharedFrom: organization,
        sharedTo: organizations[0],
        brands,
    };
};

export const findKoddiProviderAccountId = (providers, orgId, selectedBrandId) => {
    let providerAccountId = null;

    // eslint-disable-next-line no-restricted-syntax
    for (const provider of Object.values(providers)) {
        if (provider?.orgId === orgId && provider?.myadsBrandId === selectedBrandId && provider?.providerAccountId) {
            providerAccountId = provider?.providerAccountId;
            break;
        }
    }

    return providerAccountId;
};

export const findKoddiCatalogBrands = (providers, orgId, selectedBrandId) => {
    let catalogBrands = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const provider of Object.values(providers)) {
        if (provider?.orgId === orgId && provider?.myadsBrandId === selectedBrandId && provider?.providerAccountId) {
            catalogBrands = provider?.catalogBrands.map(({ brandName, brandId }) => ({
                name: brandName,
                value: brandId,
            }));
            break;
        }
    }

    return catalogBrands;
};

export const advertiserIdJsx = (getProvidersLoadState, advertiserIdValue) => {
    if (advertiserIdValue && getProvidersLoadState === RequestStatus.DONE) {
        return (
            <FlyoutContent label="Advertiser ID">
                <TextDisabled name="advertiserId" id="advertiserId" text={advertiserIdValue} />
            </FlyoutContent>
        );
    }
    if (getProvidersLoadState === RequestStatus.LOADING) {
        return (
            <FlyoutContent label="Advertiser ID">
                <TextDisabled name="advertiserId" id="advertiserId" text="Advertiser ID is loading..." />
            </FlyoutContent>
        );
    }
    return (
        <FlyoutContent label="Advertiser ID">
            <TextDisabled name="advertiserId" id="advertiserId" text="No Advertiser ID." />
        </FlyoutContent>
    );
};
