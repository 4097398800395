import request, { formatGetOptions as getOptions, formatPostOptions, formatPutOptions } from '../utils/request';
import { BASE_URL_V2 } from './api-service/Url_Constants';
import {
    addBrandResponseMap,
    addOrgResponseMap,
    formatAccountResponse,
    formatUpdatedBrandResponse,
    organizationFieldsMap,
    orgsAndBrandsFieldsMap,
} from './api-service/maps';

export const fetchOrganizations = async () => {
    const options = getOptions();
    const response = await request(`${BASE_URL_V2}/accounts/ui/listOrgs`, options);
    return response.payload.map((org) => organizationFieldsMap(org));
};

export const fetchAdminOrgs = async () => {
    const options = getOptions();

    const response = await request(`${BASE_URL_V2}/accounts/ui/listOrgsAndBrands`, options);

    return response.payload.map((org) => orgsAndBrandsFieldsMap(org));
};

export const getOrgTypes = async () => {
    const options = getOptions();
    const response = await request(`${BASE_URL_V2}/accounts/org/listOrgTypes`, options);
    return response.payload.map((type) => ({
        name: type,
        value: type,
    }));
};

export const addBrand = async (values) => {
    const options = formatPostOptions(values.payload);
    const response = await request(`${BASE_URL_V2}/accounts/brand/create`, options);
    return addBrandResponseMap(response.payload);
};

export const updateBrand = async (brandId, brandName) => {
    const requestData = { name: brandName, brand_id: brandId, state: 'active' };
    const options = formatPutOptions(requestData);
    const { payload } = await request(`${BASE_URL_V2}/accounts/brand/update`, options);
    return formatUpdatedBrandResponse(payload);
};

export const addOrg = async (values) => {
    const options = formatPostOptions({ name: values.name, org_type: values.type, state: 'active' });
    const response = await request(`${BASE_URL_V2}/accounts/org/create`, options);
    return addOrgResponseMap(response.payload);
};

export const updateOrg = async (values) => {
    const options = formatPutOptions({
        name: values.name,
        org_type: values.type,
        org_id: values.orgId,
        state: 'active',
    });

    const response = await request(`${BASE_URL_V2}/accounts/org/update`, options);
    return response;
};

export const updateAccount = async (values) => {
    const options = formatPutOptions({ ...values });
    const response = await request(`${BASE_URL_V2}/accounts/provider/accounts/update`, options);

    return formatAccountResponse(response.payload);
};

export const addAccount = async (values) => {
    const options = formatPostOptions({
        ...values,
    });
    const response = await request(`${BASE_URL_V2}/accounts/provider/accounts/create`, options);

    return formatAccountResponse(response.payload);
};
