import { call, put, fork, select, takeLeading } from 'redux-saga/effects';
import { getAccountBrandsApi, shareOrgBrands, removeShareOrgBrands } from 'api/user-management';
import {
    addAccount,
    addBrand,
    addOrg,
    fetchOrganizations,
    getOrgTypes,
    updateAccount,
    updateBrand,
    updateOrg,
} from 'api/organizations';
import { getProviders, createKoddiProvider, updateKoddiProviderAccount, listProviderAccounts } from 'api/providers';
import { loadCampaigns } from 'containers/App/actions';
import {
    addAccountAction,
    addAccountErrorAction,
    addAccountSuccessAction,
    addBrandAction,
    addBrandErrorAction,
    addBrandSuccessAction,
    addOrgAction,
    addOrgErrorAction,
    addOrgSuccessAction,
    createKoddiProviderError,
    createKoddiProviderSuccessAction,
    loadAccountsAction,
    loadAccountsErrorAction,
    loadAccountsSuccessAction,
    loadBrandAction,
    loadBrandErrorAction,
    loadBrandSuccessAction,
    loadOrgTypesAction,
    loadOrgTypesErrorAction,
    loadOrgTypesSuccessAction,
    loadOrganizationListAction,
    loadOrganizationListErrorAction,
    loadOrganizationListSuccessAction,
    loadProvidersAction,
    loadProvidersError,
    loadProvidersSuccessAction,
    updateAccountAction,
    updateAccountErrorAction,
    updateAccountSuccessAction,
    updateBrandAction,
    updateBrandErrorAction,
    updateBrandSuccessAction,
    updateKoddiProviderError,
    updateKoddiProviderSuccessAction,
    updateOrgAction,
    updateOrgErrorAction,
    updateOrgSuccessAction,
    updateOrganizationAction,
} from './slice';
import { selectFeatureFlags } from '../../utils/featureFlags/selectors';
import { SHARE_ORG, REMOVE_SHARE_ORG } from '../App/constants';
import { enqueueSnackbar } from '../../notification/actions';
import { NOTICES } from '../../notification/constants';
import { UPDATE_KODDI_PROVIDER, CREATE_KODDI_PROVIDER } from './OrgFlyouts/SendToKoddi/constants';

export function* getOrganizationSaga() {
    try {
        const organizations = yield call(fetchOrganizations);
        yield put(loadOrganizationListSuccessAction(organizations));
    } catch (error) {
        yield put(loadOrganizationListErrorAction(error));
    }
}

export function* createKoddiProviderSaga(values) {
    try {
        const koddiProvider = yield call(createKoddiProvider, values);
        yield put(createKoddiProviderSuccessAction(koddiProvider));
        yield put(
            enqueueSnackbar({
                message: NOTICES.SEND_TO_KODDI_SUCCESS,
                options: { variant: 'success' },
            }),
        );
    } catch (error) {
        yield put(createKoddiProviderError(error));
        yield put(
            enqueueSnackbar({
                message: NOTICES.SEND_TO_KODDI_ERROR,
                options: { variant: 'error' },
            }),
        );
    }
}

export function* addBrandSaga(values) {
    try {
        const brandAdded = yield call(addBrand, values);
        yield put(addBrandSuccessAction(brandAdded));
        yield put(
            enqueueSnackbar({
                message: NOTICES.ADD_BRAND_SUCCESS,
                options: { variant: 'success' },
            }),
        );
    } catch (error) {
        yield put(addBrandErrorAction(error));
        yield put(
            enqueueSnackbar({
                message: NOTICES.ADD_BRAND_ERROR,
                options: { variant: 'error' },
            }),
        );
    }
}

export function* updateBrandSaga(action) {
    try {
        const brandUpdated = yield call(updateBrand, action.payload.brandId, action.payload.name);
        yield put(updateBrandSuccessAction(brandUpdated));
        yield put(
            enqueueSnackbar({
                message: NOTICES.UPDATE_BRAND_SUCCESS,
                options: { variant: 'success' },
            }),
        );
        yield put(loadOrganizationListAction());
        yield put(loadCampaigns());
    } catch (error) {
        yield put(updateBrandErrorAction(error));
        yield put(
            enqueueSnackbar({
                message: NOTICES.UPDATE_BRAND_ERROR,
                options: { variant: 'error' },
            }),
        );
    }
}

export function* getProvidersSaga(values) {
    try {
        const data = yield call(getProviders, values);
        yield put(loadProvidersSuccessAction(data));
    } catch (error) {
        yield put(loadProvidersError(error));
    }
}

export function* updateKoddiProviderSaga(values) {
    try {
        const data = yield call(updateKoddiProviderAccount, values);
        yield put(updateKoddiProviderSuccessAction(data));
        yield put(
            enqueueSnackbar({
                message: NOTICES.PROVIDER_UPDATED_SUCCESS,
                options: { variant: 'success' },
            }),
        );
    } catch (error) {
        yield put(updateKoddiProviderError(error));
        yield put(
            enqueueSnackbar({
                message: NOTICES.PROVIDER_UPDATED_ERROR,
                options: { variant: 'error' },
            }),
        );
    }
}

export function* loadBrandsSaga() {
    try {
        const flags = yield select(selectFeatureFlags);
        const brands = yield call(getAccountBrandsApi, flags);
        yield put(loadBrandSuccessAction(brands));
    } catch (error) {
        yield put(loadBrandErrorAction(error));
    }
}

export function* loadAccountsSaga() {
    try {
        const accounts = yield call(listProviderAccounts);
        yield put(loadAccountsSuccessAction(accounts));
    } catch (error) {
        yield put(loadAccountsErrorAction(error));
    }
}

export function* loadOrgTypesSaga() {
    try {
        const orgTypes = yield call(getOrgTypes);
        yield put(loadOrgTypesSuccessAction(orgTypes));
    } catch (error) {
        yield put(loadOrgTypesErrorAction(error));
    }
}

export function* shareOrgCampaigns({ sharedData }) {
    try {
        yield call(shareOrgBrands, sharedData);
        yield put(
            enqueueSnackbar({
                message: NOTICES.SHARE_ORG_CAMPAIGNS_SUCCESS,
                options: { variant: 'success' },
            }),
        );
    } catch (error) {
        yield put(
            enqueueSnackbar({
                message: NOTICES.SHARE_ORG_CAMPAIGNS_ERROR,
                options: { variant: 'error' },
            }),
        );
    }

    // Manually update org list with new share then validate info from backend
    const { sharedFrom, sharedTo, brands } = sharedData;
    const infoShared = {
        name: sharedTo.name,
        createdAt: undefined,
        userCount: 0,
        orgId: sharedTo.value,
        brands: brands.map((brand) => brand.name),
        campaignCount: 0,
    };
    const newOrg = {
        ...sharedFrom,
        shared: [...sharedFrom.sharedOrg, infoShared],
    };

    yield put(updateOrganizationAction(newOrg));
    yield fork(getOrganizationSaga);
}

export function* removeOrgCampaigns({ sharedData }) {
    try {
        yield call(removeShareOrgBrands, sharedData);
        yield put(
            enqueueSnackbar({
                message: NOTICES.REMOVE_ORG_CAMPAIGNS_SUCCESS,
                options: { variant: 'success' },
            }),
        );
        yield fork(getOrganizationSaga);
    } catch (error) {
        yield put(
            enqueueSnackbar({
                message: NOTICES.REMOVE_ORG_CAMPAIGNS_ERROR,
                options: { variant: 'error' },
            }),
        );
    }
}

export function* addOrgSaga(values) {
    try {
        const orgAdded = yield call(addOrg, values.payload);
        yield put(addOrgSuccessAction(orgAdded));
        yield put(
            enqueueSnackbar({
                message: NOTICES.ADD_ORGANIZATION_SUCCESS,
                options: { variant: 'success' },
            }),
        );
    } catch (error) {
        yield put(addOrgErrorAction(error));
        yield put(
            enqueueSnackbar({
                message: NOTICES.ADD_ORGANIZATION_ERROR,
                options: { variant: 'error' },
            }),
        );
    }
}

export function* updateOrgSaga(values) {
    try {
        yield call(updateOrg, values.payload);
        yield put(updateOrgSuccessAction(values.payload));
        yield put(
            enqueueSnackbar({
                message: NOTICES.UPDATE_ORGANIZATION_SUCCESS,
                options: { variant: 'success' },
            }),
        );
        yield put(loadBrandAction());
    } catch (error) {
        yield put(updateOrgErrorAction(error));
        yield put(
            enqueueSnackbar({
                message: NOTICES.UPDATE_ORGANIZATION_ERROR,
                options: { variant: 'error' },
            }),
        );
    }
}

export function* updateAccountSaga(values) {
    try {
        const updatedAccount = yield call(updateAccount, values.payload);
        yield put(updateAccountSuccessAction({ ...updatedAccount }));
        yield put(
            enqueueSnackbar({
                message: NOTICES.UPDATE_ACCOUNT_SUCCESS,
                options: { variant: 'success' },
            }),
        );
    } catch (error) {
        yield put(updateAccountErrorAction(error));
        yield put(
            enqueueSnackbar({
                message: NOTICES.UPDATE_ACCOUNT_ERROR,
                options: { variant: 'error' },
            }),
        );
    }
}

export function* addAccountSaga(values) {
    try {
        const accountAdded = yield call(addAccount, values.payload);
        yield put(addAccountSuccessAction(accountAdded));
        yield put(
            enqueueSnackbar({
                message: NOTICES.ADD_ACCOUNT_SUCCESS,
                options: { variant: 'success' },
            }),
        );
        yield put(loadBrandAction());
        yield put(loadOrganizationListAction());
        yield put(loadCampaigns());
    } catch (error) {
        yield put(addAccountErrorAction(error));
        yield put(
            enqueueSnackbar({
                message: NOTICES.ADD_ACCOUNT_ERROR,
                options: { variant: 'error' },
            }),
        );
    }
}

export default function* organizationListPageSaga() {
    yield takeLeading(loadOrganizationListAction().type, getOrganizationSaga);
    yield takeLeading(loadBrandAction().type, loadBrandsSaga);
    yield takeLeading(loadAccountsAction().type, loadAccountsSaga);
    yield takeLeading(loadOrgTypesAction().type, loadOrgTypesSaga);
    yield takeLeading(SHARE_ORG, shareOrgCampaigns);
    yield takeLeading(REMOVE_SHARE_ORG, removeOrgCampaigns);
    yield takeLeading(CREATE_KODDI_PROVIDER, createKoddiProviderSaga);
    yield takeLeading(loadProvidersAction().type, getProvidersSaga);
    yield takeLeading(UPDATE_KODDI_PROVIDER, updateKoddiProviderSaga);
    yield takeLeading(addBrandAction().type, addBrandSaga);
    yield takeLeading(addOrgAction().type, addOrgSaga);
    yield takeLeading(updateOrgAction().type, updateOrgSaga);
    yield takeLeading(updateBrandAction().type, updateBrandSaga);
    yield takeLeading(addAccountAction().type, addAccountSaga);
    yield takeLeading(updateAccountAction().type, updateAccountSaga);
}
