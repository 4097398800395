import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { loadOrgTypesAction, addOrgAction } from 'containers/OrganizationPage/slice';
import { selectOrgTypes } from 'containers/OrganizationPage/selectors';
import { createStructuredSelector } from 'reselect';
import AddAndEditOrgForm from '../Shared/AddAndEditOrgForm';
import DialogBox from '../../../../components/DialogBox';

export function AddOrgFlyout({ getOrgTypes, orgTypes, createOrg, closeFlyout, organizations }) {
    const [submitValues, setSubmitValues] = useState(null);

    useEffect(() => {
        getOrgTypes();
    }, [getOrgTypes]);

    const onSubmit = (values) => {
        setSubmitValues(values);
    };

    const initialValues = {
        name: '',
        type: '',
    };

    const onSubmitAddOrgCancel = () => {
        setSubmitValues(null);
    };

    const onAddOrgSubmitProceed = () => {
        const newOrgValues = { name: submitValues.name, type: submitValues.type[0].value };
        createOrg(newOrgValues);
        closeFlyout();
    };

    return (
        <>
            <AddAndEditOrgForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                submitDisabled={submitValues !== null}
                orgTypes={orgTypes}
                organizations={organizations}
                isEdit={false}
            />
            <DialogBox
                open={submitValues !== null}
                onConfirm={onAddOrgSubmitProceed}
                onCancel={onSubmitAddOrgCancel}
                title="Add Organization Confirmation"
                message="Are you sure you want to add a new Organization?"
                confirmText="Yes, Add Organization"
            />
        </>
    );
}

AddOrgFlyout.propTypes = {
    props: PropTypes.shape({
        options: PropTypes.array,
    }),
    getOrgTypes: PropTypes.func,
    orgTypes: PropTypes.array,
    createOrg: PropTypes.func,
    closeFlyout: PropTypes.func,
    organizations: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    orgTypes: selectOrgTypes,
});

export function mapDispatchToProps(dispatch) {
    return {
        getOrgTypes: () => dispatch(loadOrgTypesAction()),
        createOrg: (values) => dispatch(addOrgAction(values)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AddOrgFlyout);
